export default {
  text001: 'AI打分',
  text002: '基於企業崗位的勝任力要求，利用AI技術對候選人的核心潛力（AI Talent DNA)、{bq}、{sy}等進行多模態分析得出的綜合評價',
  text003: 'L4-AI視頻總分: ',
  text004: '生成中',
  text005: '模型訓練中',
  text006: 'L2-AI視頻總分',
  text007: '視頻人工總分',
  text008: 'L3-AI篇章級語義分析得分',
  text008_1: 'AI篇章級語義分析得分',
  text009: '基於篇章級別的自然語言演算法對候選人回答問題的品質的綜合判斷得分。',
  text010: '{label}-AI總分排名占整體候選人情況',
  text011: '分析得分',
  text012: '基於人工智慧的人臉識別演算法對候選人的五官和輪廓的綜合判斷打分。',
  text013: '基於自主研發領先人工智慧語音演算法對候選人回答面試問題時的音量、音調等五大聲音維度進行綜合分析，從而判斷候選人在語音表達上的水準。',
  text014: '基於宏錶情分析的電腦視覺演算法對候選人情緒和回答問題真實性的綜合判斷得分。',
  text015: '以下為該候選人主要錶情（TOP 3 錶情分析）',

  text016: '該候選人在面試過程中沒能展現出自己具備勝任該崗位的能力，因此在未來的工作中TA可能無法完成上級分配的工作任務。',
  text017: '該候選人可能只滿足了少數勝任力維度上的最低要求，因此在未來的工作中TA可能很難完成上級所分配的任務。',
  text018: '該候選人在面試過程中證明了自己具備完成工作所需的基本勝任力水準，因此TA應該具備完成任務，達成基本目標的能力。',
  text019: '該候選人在面試過程中展現出了良好的勝任力水準，因此TA應該具備超越績效標準的能力。',
  text020: '該候選人在面試過程中展現出了優秀的勝任力水準，因此在未來的工作中TA應該能夠出色地完成工作任務。',

  text021: '錶情',
  text022: '聲音',
  text023: '職業形象',

  text024: '該候選人在面試過程中表現得非常緊張，不適，大部分時間都展現出害怕，經驗，討厭等消極錶情。',
  text025: '該候選人在面試過程中表現得較為緊張，不適。多次出現難過，驚訝，討厭等消極錶情。',
  text026: '該候選人在面試過程中表現平穩，沒有明顯的情緒起伏和變化。',
  text027: '該候選人在面試過程中變現的較為輕鬆，自如，自信，常常面帶微笑。',
  text028: '該候選人在面試過程中表現得非常輕鬆，愉悅，自信，始終面帶笑容。',

  text029: '該候選人在面試過程中可能頻繁出現：聲音卡頓，語速過快或過慢，毫無節奏等問題，使得聽眾感到明顯的不適。',
  text030: '候選人在面試過程中可能多次出現：聲音卡頓，語速過快或過慢，毫無節奏等問題、引起聽眾的不適。',
  text031: '面試過程中，該候選人聲音的整體表現一般，可能存在一些卡頓，語速不自然，說話缺乏節奏感等問題。',
  text032: '面試過程中，該候選人聲音的整體表現良好，語言流暢，語速自然，但可能存在少許瑕疵（如：缺乏節奏感，少量卡頓等）。',
  text033: '面試過程中，該候選人聲音的整體表現表現優異，語言流暢，語速自然，聲音抑揚頓挫，婉轉動聽，令人沉醉。',

  text034: '難以接受，可能帶來非常強烈的視覺衝擊，誘發消極的情緒體驗。',
  text035: '該候選人職業形象能夠引起輕微的不適感。',
  text036: '大眾職業形象，看到之後不會出現明顯的情緒體驗和波動。',
  text037: '該候選人職業形象帶來的感受高於平均水準，看起來比較舒服。',
  text038: '明星級別的職業形象，看到之後能夠帶來明顯的愉悅感。',

  text039: '分析詳情',
  text040: '報告總結',
  text041: '口音總分-中文',
  text042: '口音總分-英文',
  text043: '綜合評價',
  text044: '視頻人工總分',
  text045: '{0}次',
  text046: '違規操作',
  text047: '答題用時',
  text048: '初次進入時間',
  text049: '完成面試時間',
  text050: '總分',
  text051: '需要注意的事項',
  text052:
    '本報告為自動生成，使用者能夠使用軟體對報告⽂本進行修改。本測驗的結果可以為企業招聘、選拔、培養管理類人才提供參考，但不能僅僅根據其中的某一份報告做出是否錄用的決定。企業可以將系統中的認知能力測試，職業性格測試，崗位專業能力測試與面試等人才素質測評方法配合使用，綜合評價候選人的素質能力，才能更精准地識別和崗位匹配度較高的優質候選人，從而提升招聘效能本報告結果注意保密，只供公司領導、⼈力資源部相關負責人或測評者本⼈閱讀。在解讀各測試分數時，應該結合招聘崗位的具體工作職責，以此來判定這些維度屬於個人優勢還是需要改進。想要瞭解如何根據各測試分數做出更為明智的招聘決定，請聯繫近嶼智能專業諮詢顧問，電話：400-0963-520。與所有人才測評工具一樣，本報告包含的所有測試結果並非絕對正確無誤，任何基於本報告所做出的用人決策，即使該決策帶給企業損失，上海近嶼智能科技有限公司對此不承擔任何法律責任。取決於個⼈情況，本報告很可能在出具的 12 個⽉內都能夠⼀直有效地反映個體的潛能。',
  text053: '版權聲明',
  text054:
    ' 本報告包含的所有內容（包括但不限於文本、數據、圖片、圖示、logo等）都受到商標法、知識產權法的保護，歸上海近嶼智能科技有限公司所有。未經上海近嶼智能科技有限公司書面許可，任何人不得使用、修改、複製、翻譯、公開及出售任何與本報告有關的內容及形式。',

  text055: '口音分析',
  text056: '口音總分',
  text057: '口音流利度',
  text058: '口音準確度',
  text059: '{0}星',

  text060: 'L2單題打分',
  text061: '本題得分',
  text062: '收起',
  text063: '詳情',
  text064: '本題權重',
  text065: '考核點',
  text066: 'L2-普通話',
  text067: 'L2-英語',
  text068: '測試候選人普通話水準能力，以中文識別候選人回答內容。',
  text069: '測試候選人英語水準能力，以英文識別候選人回答內容。',

  text070: 'L2-是否意願',
  text071: '用於判斷候選人對面試內容的接受意願，如“是否熟練使用手機”。',
  text072: 'L2-關鍵字命中',
  text073: 'AI將分析候選人回答內容是否含所需關鍵字要點，語意相關度匹配。',
  text074: 'L2-語速',
  text075: '考核候選人說話語速正常程度，是否過快過慢。',
  text076: 'L2-語詞豐富',
  text077: '候選人描述事物的辭彙豐富程度，以及詳細程度。',
  text078: 'L2-語意流暢',
  text079: '候選人表達事物的連貫性以及流利程度。',
  text080: 'L2-職業形象',
  text081: '候選人是否五官端正，符合通常普遍審美。',
  text082: 'L2-外貌年齡',
  text083: '識別候選人外觀年齡，長相是否年輕。',
  text084: 'L2-保持微笑',
  text085: '考核候選人在面試過程中是否保持微笑。',
  text091: '報告說明',
  text092: '測評總覽',
  text094: '情緒穩定性得分',
  text093: '候選人勝任力綜合得分',
  text095: '評估有效性',
  text096: '答題用時',
  text097: '初次進入時間',
  text098: '完成面試時間',
  text099: '勝任力報告維度',
  text100: '情緒穩定性報告維度',
  text101: '沒有內容可以查看',
  text102: '分',
  text103: '在線考試',
  text104: '該模塊涵蓋了候選人在以下所有題目類別上的答題情況，包括：單選題，多選題，判斷題，排序題，論述題，口音題。',
  text105: '選擇題，判斷題，排序題的分數只有在設定好題目分數以及標準答案後，才會涵蓋在圖表的總分當中。',
  text106: '論述題的分數只有在設定好題目分數以及面試官評價過後，才會涵蓋在圖表的總分當中。',
  text107: '備註',
  text108: '得分',
  text109: '答题情况',
  text110: '正確題數',
  text111: '錯誤題數',
  text130: 'L5-AI視頻總分: ',
  text130_1: 'AI視頻總分: ',
  text130_2: 'L5-AI視頻: ',
  text131: '(AI追問)',
  text132: '本題不計分',
  text133: '回答正確',
  text134: '回答不全',
  text135: '回答錯誤',
  text136: '正確',
  text137: '錯誤',
  text138: '未設置',
  text139: '已擊敗',
  text140: '的候選人',
  text141: '口音題總分(中文)：',
  text142: '口音題總分(英文)：',
  text143: '{0}分',
  text144: '視頻記錄',
  text145: 'AI維度分析',
  text146: '勝任力維度分數解讀',
  text147: '歸納總結：',
  text148: '下一輪重點考察建議：',
  text149: '勝任力定義：',
  text150: '勝任力評分標準：',
  text151: '候選人綜合分析：',
  text152: '*如果您需要詳細了解各項勝任力的具體得分、評分依據及進一步考察建議，請繼續查閱各個勝任力的單獨評估報告。',
  text153: '評分分析：',
  text154: '星',
  LevelOfCompetency: '勝任力素質<br />星級',
  LevelOfCompetency_1: '勝任力素質',
  text155: '英語能力',
  text156: '實力評估：',
  text157: '能理解和使用簡單的日常表達，但在實際交流中需大量輔助，溝通較為困難。',
  text158: '能進行基本的對話，處理熟悉話題，但在複雜或陌生情境中依然存在較大障礙。',
  text159: '能在熟悉的環境中進行較為流利的交流，處理日常工作和生活中的大部分英語需求。',
  text160: '能够自如應對複雜的英語交流，準確表達自己的觀點，具備較高的語言流暢性和準確性。',
  text161: '能流利、精准地運用英語處理各種專業或抽象話題，溝通能力接近母語水准。',
  text162: '入門',
  text163: '初級',
  text164: '中級',
  text165: '高級',
  text166: '精通',
  text167: '英語能力評分標準：',
  text168: '通過多個維度對口語表達進行全面評估，涵蓋準確性、流暢度、語法、詞彙和主題契合等方面。 多維度的評分標準確保了對語言應用能力的客觀反映，結果具有較高的可靠性和精准度。 該標準為英語能力要求較高的崗位提供了有效的篩選工具，幫助企業準確評估候選人的溝通能力是否能勝任崗位需求，特別是在需要頻繁使用英語交流的工作場合。 通過這種客觀且細緻的評估管道，企業能够更好地確保招聘到具備較强口語溝通能力的候選人，减少招聘過程中的語言評估偏差。',
  text169: '英語能力各維度得分：',
  text170: '準確性',
  text171: '語意流暢',
  text172: '語法',
  text173: '詞彙',
  text174: '主題契合',
  text175: '低',
  text176: '中',
  text177: '高',
  text178: '綜合勝任力得分',
  text179: '情緒穩定性',
  
  yxexsx: '1星：完全不勝任 \xa0\xa0\xa0 2星：不勝任 \xa0\xa0\xa0 3星：勝任 \xa0\xa0\xa0 4星：優秀 \xa0\xa0\xa0 5星：超出期望',
  text_aigd: 'AI面試等級說明',
  text_l2: '以關鍵字識別為代表的初級AI科技，信效度較低，不建議作為候選人面試是否通過判斷的依據，請謹慎參攷。',
  text_l3:
    '基於篇章級別語義識別算灋，深度輔助人類面試官對候選人的勝任力進行判斷，請關注L3級別的分數不考慮候選人的錶情、聲音等項目的表現。',
  text_l4:
    '基於近嶼智慧自研的AI多模態算灋，對候選人的篇章級別語義、錶情和聲音進行綜合判斷，判斷準確率可以通過雙盲人機對比實驗。',
  text_l5:
    '基於近嶼智慧自研的AIGC多模態大模型，AI面試官可以做到多輪對話、千人千問、實时追問，對候選人的篇章級別語義、錶情和聲音進行綜合判斷，判斷準確率可以通過雙盲人機對比實驗。',
}
