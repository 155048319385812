/* eslint-disable quotes */
export default {
  text001: 'Meritas-mental health question',
  text002: `AI RecruiTas' Meritas-mental health Questionnaire is based on the two-factor model theory of mental health (Keyes, 1998), which takes "positive psychological indicators" and "psychological symptom indicators" as two essential factors for mental health diagnosis, in order to comprehensively evaluate the candidate's mental health. Positive psychological indicators include: self-affirmation, hope, optimism, and resilience. Individuals with these psychological states can quickly recover from negative events, mediate their own psychological states, and adapt to the environment, so they often show higher work efficiency and stronger job satisfaction at work. Psychological symptom indicators include: anxiety, depression, interpersonal sensitivity, hostility, and paranoia, which are the five most common psychological symptoms in the workplace. `,
  text003: `{name}'s mental health:`,
  text004: 'At',
  text005: 'Specific performance',
  text006: 'Positive psychology',
  text007: 'Negative psychology',
  text008: ' {0} points',
  text009: 'There is no content to view',
  text010: 'In the process of interpersonal communication, I have a strong sense of inferiority complex, feel uneasy, show obvious discomfort, and reject all kinds of social activities',
  text011: 'Great, outgoing, active, able to deal with interpersonal relationships freely, and willing to communicate with others. ',
  text012: 'Depressed mood, lack of vitality, lack of energy to do anything, lack of confidence in life, feeling of no value, etc. ',
  text013: `Optimistic and positive, happy mood, recognition of one's own value, full of vitality in work and life. `,
  text014: 'The mood is capricious, irritable, impulsive, and may even have delusions. ',
  text015: 'Peace of mind, relaxed, stable, able to actively mediate emotions after encountering troubles, without panic. ',
  text016: 'Difficult to control temper, prone to arguments, lack of basic trust in others, difficult to cooperate, and often have conflicts or arguments with others. ',
  text017: `Sunshine, optimistic, easy-going, friendly, don't like to argue, trust others, willing to cooperate with anyone.`,
  text018: `Suspicious, sensitive, stubborn, unwilling to trust others, and difficult to accept other people's suggestions and opinions`,
  text019: `Humble, flexible, objective and rational, able to listen to and accept other people's suggestions and opinions with an open mind, and make corrections. `,
  text020: 'Has a strong need for growth and achievement, and can persevere in pursuit of the completion of goals',
  text021: 'Obedience, lack of achievement motivation, lack of goals, easy to withdraw, self-defeating',
  text022: `Positive, realistic, flexible, attribute success to one's own ability, and attribute failure to unstable factors such as luck. `,
  text023: `Negative, pessimistic, attribute failure to one's own ability, attribute success to unstable factors such as luck`,
  text024: 'High self-confidence, even in the face of challenging tasks, still full of confidence and strive to achieve goals',
  text025: "Low self-confidence, often self-denial, often hesitant in the face of difficulties, doubting one's own ability. " ,
  text026: 'Strong-willed, when hit or faced with adversity, can adjust itself in time to recover quickly',
  text027: 'Weak will, when encountering setbacks or difficulties, tend to give up and escape',

  text028: 'Very healthy mental state',
  text029: 'Healthier mental state',
  text030: 'Normal status',
  text030_1: 'Normal',
  text031: 'Partial healthy mental state',
  text032: 'Completely disabled mental state',
  text033: 'Interpersonal sensitivity',
  text034: 'Depressed',
  text035: 'Anxious',
  text036: 'Hostile',
  text037: 'Paranoia',
  text038: 'Hope',
  text039: 'Optimistic',
  text040: 'Self-affirmation',
  text041: 'Toughness',
}
