/* eslint-disable quotes */
export default {
  text001: 'AI scoring',
  text002: `Based on the competency requirements of the enterprise position, the comprehensive evaluation obtained by using AI technology to conduct multi-modal analysis on the candidate's core potential (AI Talent DNA), {bq}, {sy}, etc.`,
  text003: 'L4-AI video total score: ',
  text004: 'Generating',
  text005: 'Model training',
  text006: 'L2-AI video total score',
  text007: 'Video artificial total score',
  text008: 'Score of semantic analysis at L3-AI chapter level',
  text008_1: 'Score of semantic analysis at AI chapter level',
  text009: `Comprehensive judgment score of the candidate's quality of answering questions based on the chapter-level natural language algorithm.`,
  text010: '{label}-AI total score ranking accounts for the overall candidate situation',
  text011: 'Analysis score',
  text012: `A face recognition algorithm based on artificial intelligence scores the comprehensive judgment of the candidate's facial features and outline.`,
  text013:
    'Based on the self-developed leading artificial intelligence voice algorithm to comprehensively analyze the five voice dimensions such as volume and pitch when candidates answer interview questions, so as to judge the level of voice expression of candidates.',
  text014: `Comprehensive judgment score of candidate's emotion and authenticity of answering questions by computer vision algorithm based on macro-expression analysis.`,
  text015: 'The following are the main expressions of the candidate (TOP 3 expression analysis)',

  text016: 'The candidate failed to show that he is qualified for the position during the interview process, so TA may not be able to complete the tasks assigned by the superior in future work. ',
  text017: 'The candidate may only meet the minimum requirements in a few competency dimensions, so it may be difficult for TA to complete the tasks assigned by the superior in future work. ',
  text018:
    'During the interview process, the candidate has proved that he has the basic competency level required to complete the job, so TA should have the ability to complete tasks and achieve basic goals. ',
  text019: 'The candidate demonstrated a good level of competency during the interview process, so TA should have the ability to exceed performance standards. ',
  text020: 'The candidate has demonstrated an excellent level of competence during the interview process, so he should be able to perform tasks well in future jobs. ',

  text021: ' Expression ',
  text022: ' Sound ',
  text023: ' Professional image ',

  text024: 'The candidate was very nervous and uncomfortable during the interview, and most of the time showed negative expressions such as fear, experience, and disgust. ',
  text025: 'The candidate was nervous and uncomfortable during the interview. Negative expressions such as sadness, surprise, and disgust appear many times. ',
  text026: 'The candidate performed smoothly during the interview, without obvious emotional ups and downs and changes. ',
  text027: 'The candidate was relaxed, comfortable, confident and always smiling during the interview process. ',
  text028: 'The candidate was very relaxed, cheerful, confident and always smiling during the interview. ',

  text029:
    'This candidate may frequently appear during the interview process: the voice freezes, the speech speed is too fast or too slow, and there is no rhythm, etc., which makes the audience feel obvious discomfort. ',
  text030: 'Candidates may appear many times during the interview process: sound stuttering, too fast or too slow speech, no rhythm and other problems, causing discomfort to the audience. ',
  text031: `During the interview, the overall performance of the candidate's voice is average, there may be some problems such as stuttering, unnatural speaking speed, and lack of rhythm in speaking. `,
  text032: `During the interview process, the candidate's overall voice performance was good, with fluent language and natural speaking speed, but there may be some flaws (such as: lack of rhythm, a small amount of stuttering, etc.). `,
  text033: `During the interview, the candidate's overall voice performance was excellent, the language was fluent, the speed of speech was natural, the voice was cadenced, melodious, and intoxicating. `,

  text034: 'It is difficult to accept, it may bring a very strong visual impact and induce negative emotional experience. ',
  text035: 'The appearance of this candidate can cause slight discomfort. ',
  text036: 'Public appearance, there will be no obvious emotional experience and fluctuations after seeing it. ',
  text037: `The feeling brought by the candidate's appearance is higher than the average level, and he looks more comfortable. `,
  text038: 'A star-level appearance can bring obvious pleasure after seeing it. ',

  text039: 'Analysis Details',
  text040: 'Report Summary',
  text041: 'Accent Total Score-Chinese',
  text042: 'Accent Total Score-English',
  text043: 'Comprehensive evaluation',
  text044: 'Video artificial total score',
  text045: '{0} times',
  text046: 'Illegal operation',
  text047: 'Time to answer questions',
  text048: 'First entry time',
  text049: 'Complete interview time',
  text050: 'Total score',
  text051: 'Matters needing attention',
  text052: `This report is automatically generated, and users can use software to modify the report text. The results of this test can provide a reference for enterprises to recruit, select, and train management talents, but they cannot make a decision on whether to hire based on just one of the reports. Enterprises can use the system's cognitive ability test, professional personality test, post professional ability test and interviews and other talent quality assessment methods in conjunction with comprehensive evaluation of the quality and ability of candidates, in order to more accurately identify high-quality candidates with a high degree of job matching. Candidates, thereby improving recruitment efficiency The results of this report are kept confidential and are only read by company leaders, relevant persons in charge of the human resources department or the evaluators themselves. When interpreting each test score, it should be combined with the specific job responsibilities of the recruiting position to determine whether these dimensions belong to personal strengths or need to be improved. If you want to know how to make more informed recruitment decisions based on various test scores, please contact Jinyu Intelligent Professional Consultant, Tel: 400-0963-520. Like all talent assessment tools, all the test results contained in this report are not absolutely correct. Any employment decision made based on this report, even if the decision brings losses to the company, Shanghai Jinyu Intelligent Technology Co., Ltd. will not be responsible for it legal liability. Depending on individual circumstances, it is likely that this report has been effective in reflecting the individual's potential for 12 months from the time it was issued. `,
  text053: 'Copyright Statement',
  text054:
    ' All content contained in this report (including but not limited to text, data, pictures, icons, logos, etc.) is protected by trademark law and intellectual property law, and is owned by Shanghai Jinyu Intelligent Technology Co., Ltd. Without the written permission of Shanghai Jinyu Intelligent Technology Co., Ltd., no one may use, modify, reproduce, translate, publish or sell any content and form related to this report. ',

  text055: 'Accent Analysis',
  text056: 'Accent total score',
  text057: 'Accent fluency',
  text058: 'Accent accuracy',
  text059: '{0} star',

  text060: 'L2 single question scoring',
  text061: 'The score for this question',
  text062: 'Collapse',
  text063: 'Details',
  text064: 'The weight of this question',
  text065: 'Assessment point',
  text066: 'L2-Mandarin',
  text067: 'L2-English',
  text068: `Test the candidate's Putonghua proficiency, and identify the content of the candidate's answer in Chinese. `,
  text069: `Test the candidate's English proficiency and identify the content of the candidate's answer in English. `,
  text070: 'L2-willing',
  text071: `Used to judge the candidate's willingness to accept the content of the interview, such as "whether you are proficient in using mobile phones." `,
  text072: 'L2-keyword hit',
  text073: `AI will analyze whether the candidate's answer contains the required key words and match the semantic relevance. `,
  text074: 'L2-speech rate',
  text075: `Assess the candidate's normal speaking speed, whether it is too fast or too slow. `,
  text076: 'L2-word rich',
  text077: `The vocabulary richness and detail of the candidate's description of things. `,
  text078: 'L2-Semantic fluency',
  text079: 'The coherence and fluency with which the candidate expresses things. ',
  text080: 'L2-professional image',
  text081: 'Whether the candidate has good facial features and conforms to the general aesthetics. ',
  text082: 'L2-appearance age',
  text083: `Identify the candidate's appearance age and whether he looks young or not. `,
  text084: 'L2-keep smiling',
  text085: 'Assess whether the candidate keeps smiling during the interview. ',
  text086: 'Poor',
  text087: 'Very poor',
  text088: 'General',
  text089: 'Good',
  text090: 'Excellent',
  text091: 'Report description',
  text092: 'Assessment overview',
  text093: 'Overall competency score for candidates',
  text094: 'Emotional stability score',
  text095: 'Evaluation effectiveness',
  text096: 'Answer time',
  text097: 'First entry time',
  text098: 'Complete the interview time',
  text099: 'Competency reporting dimension',
  text100: 'Report dimensions of emotional stability',
  text101: 'There is nothing to view',
  text102: 'score',
  text103: 'Online examination',
  text104: "This module covers the candidates' performance in all of the following question categories: single choice, multiple choice, true or false choice, ranking, essay, and accent.",
  text105: 'The scores of multiple choice, decision and ranking questions will be included in the total score of the chart only after the scores and standard answers have been set.',
  text106: 'The score of the essay question will not be included in the overall score of the chart until the score has been set and the interviewer has commented on it.',
  text107: 'Remarks',
  text108: 'Score',
  text109: 'Answer situation',
  text110: 'Correct number of questions',
  text111: 'Number of errors',
  text130: 'L5-AI score: ',
  text130_1: 'AI score: ',
  text130_2: 'L5-AI video: ',
  text131: '(AI follow-up question)',
  text132: 'The question is not scored',
  text133: 'Correct answer',
  text134: 'Incomplete answer',
  text135: 'Wrong answer',
  text136: 'Correct',
  text137: 'Wrong',
  text138: 'No set',
  text139: 'Have defeated',
  text140: 'of candidates',
  text141: 'Accent Total Score(Chinese): ',
  text142: 'Accent Total Score(English): ',
  text143: '{0}分',
  text144: 'Video Log',
  text145: 'AI Dimensional Analysis',
  text146: 'Competency Interpretation',
  text147: 'Sum up: ',
  text148: 'Recommendations for the next round of key inspection: ',
  text149: 'Competency definition: ',
  text150: 'Competency rating criteria: ',
  text151: 'Candidate Analysis: ',
  text152:
    '*If you need to know the specific scores of various competencies, the basis of scoring, and further examination suggestions, please continue to refer to the separate assessment reports of each competency.',
  text153: 'Score Analysis: ',
  text154: 'star',
  LevelOfCompetency: 'Level of<br />Competency',
  LevelOfCompetency_1: 'Competency',
  text155: 'English Proficiency',
  text156: 'Proficiency Evaluation：',
  text157: 'Understands simple everyday expressions but struggles significantly in real conversations.',
  text158: 'Handles basic conversations on familiar topics but has difficulty in more complex or unfamiliar situations.',
  text159: 'Converses fairly fluently in familiar settings, managing most everyday tasks and interactions.',
  text160: 'Navigates complex interactions with ease, expressing ideas clearly and maintaining strong fluency.',
  text161: 'Communicates fluently and precisely on a wide range of topics, approaching native-level proficiency.',
  text162: 'Basic',
  text163: 'Beginner',
  text164: 'Mid',
  text165: 'Pro',
  text166: 'Expert',
  text167: 'English Proficiency Evaluation Standards: ',
  text168: 'The assessment provides a comprehensive evaluation of spoken English across multiple dimensions, including accuracy, fluency, grammar, vocabulary, and relevance to the topic. This multi-dimensional scoring system ensures an objective reflection of language proficiency, delivering highly reliable and precise results. It serves as an effective tool for screening candidates in roles where strong English skills are essential, particularly in positions requiring frequent communication in English. By offering an objective and detailed evaluation, this standard helps organizations better assess whether candidates meet the communication demands of the job, reducing bias in the language assessment process.',
  text169: 'English Proficiency Scores By Dimension: ',
  text170: 'Accuracy',
  text171: 'Smooth semantics',
  text172: 'Grammar',
  text173: 'Vocabulary',
  text174: 'Topic Relevance',
  text175: 'Low',
  text176: 'Middle',
  text177: 'High',
  text178: 'Overall competency score',
  text179: 'Emotional stability',
  
  yxexsx:
    '1 star: Completely incompetent; 2 stars: Incompetent; 3 stars: Competent; 4 stars: Excellent; 5 stars: Exceed expectations;',
  text_aigd: 'AI Interview Grade Description',
  text_l2:
    'The primary AI technology represented by keyword recognition has low reliability and validity, and is not recommended as the basis for judging whether candidates pass the interview. Please refer to it with caution.',
  text_l3:
    "Based on the text level semantic recognition algorithm, the human interviewer is deeply assisted in judging the candidate's competency. Please pay attention to the L3 scores, regardless of the candidate's facial expression, voice and other performance items.",
  text_l4:
    "Based on the AI multi-modal algorithm developed by Jinyu Intelligence, the candidate's text level semantic, expression and voice can be comprehensively judged, and the judgment accuracy can be compared with the double-blind machine experiment.",
  text_l5:
    "Based on the AIGC multi-modal large model developed by Near Yu Intelligence, AI interviewers can achieve multiple rounds of dialogue, thousands of questions, real-time questioning, and comprehensive judgment of the candidate's text level semantics, expressions and voices, and the judgment accuracy rate can be compared by double-blind machine experiments.",
}
