<template>
  <div>
    <div v-if="interviewResult.colorBlindess && interviewResult.colorBlindess.resultStatus">
      <div class="colorBlindess">
        <div style="display: flex;margin-right: 12px;">
          <div>{{ $t('com.report.onlinetest.text032') }}：</div>
          <div style="color: #ffca3a;font-weight: normal;">
            {{ 
              interviewResult.colorBlindess.resultStatus == 1 ? $t('com.report.onlinetest.text035'):
              interviewResult.colorBlindess.resultStatus == 2 ? $t('com.report.onlinetest.text035_1') :
              interviewResult.colorBlindess.resultStatus == 3 ? $t('com.report.onlinetest.text035_2') :''
            }}
          </div>
        </div>
        <div>
          {{ $t('com.report.onlinetest.text032_1') }}：<span>{{ interviewResult.colorBlindess.successCount }}</span>
        </div>
        <div>
          {{ $t('com.report.onlinetest.text032_2') }}：<span style="color: rgb(225, 85, 85)">{{ interviewResult.colorBlindess.errorCount }}</span>
        </div>
      </div>
      <div class="interview_time" v-if="false">
        <div class="use_time">
          <img class="clock" height="20px" src="@/assets/clock.png" alt="" />
          {{ $t('com.report.reportV3.text047') }}：{{ interviewResult.colorBlindess.answerTime }}
        </div>
      </div>
    </div>
    <div class="exam-summary" v-if="questionArray && questionArray.length">
      <div class="question-detail-wrapper">
        <template v-for="(question, qIndex) in questionArray">
          <template v-for="(q, index) in Array.isArray(question) ? question : [question]">
            <div class="question">
              <div v-if="qIndex == 0" class="question-type" style="display: flex;align-items: center;">
                <span class="top__title">
                  {{ $t('com.report.onlinetest.text037') }}
                </span>

              </div>
              
              <div class="question-type">
                <span style="font-size: 16px;">{{ qIndex + 1 }}.</span>
              </div>
              <img :src="q.question.problem" height="200px" alt="" srcset="" />
              <div class="question-item">
                <div style="flex: 1;">
                  {{ $t('com.report.onlinetest.text033') }}：<span>{{ q.question.right_key }}</span>
                </div>
                <div style="flex: 1;display: flex;">
                  {{ $t('com.report.onlinetest.text034') }}：<span :style="q.question.right_key!==q.question.answer?{color: 'rgb(225, 85, 85)'}:''">{{ q.question.answer }}</span>
                </div>
                <div></div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <CopyrightNotice />
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
import CopyrightNotice from '@/components/reportV3/common/copyright-notice.vue'
import Empty from '@/components/reportV3/common/empty.vue'
import EventBus from '@/common/event-bus'

// import i18n from '../../utils/i18n'

// @Component({  })
export default {
  components: { CopyrightNotice, Empty },
  computed: {
    answerResult() {
      return this.$store.state.answerResult || []
    },

    questionArray() {
      return this.answerResult.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return info.isCustom === 1 && info.question?.problem_answer_method == 11
      })
    },
    interviewResult() {
      return this.$store.state.interviewResult
    }
  },
  
  methods: {

  },
}
</script>
<style lang="scss" scoped>
.exam-summary {
  margin: 20px 0 40px;
}
.summary-title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.85);
  line-height: 22px;
  margin-bottom: 8px;
}
.colorBlindess{
  display: flex; 
  justify-content: space-between; 
  margin: 20px 0 10px;
  span{
    color: #333;
    font-size: 14px;
    font-weight: bold;
  }
}
.line2 {
  font-size: 12px;
  color: #999999;
  margin-bottom: 40px;
}
.interview_time {
  width: 100%;
  margin: 10px 0px;
  padding: 10px;
  background: #f3f9ff;
  border-radius: 8px;
  .use_time {
    // margin-bottom: 10px;
    font-size: 14px;
    color: #333333;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    .trans_icon {
      color: #1890ff;
      .active_icon {
        transform: rotate(90deg);
      }
    }
    .clockimg {
      width: 16px;
      height: 16px;
      margin-right: 2px;
      position: relative;
      top: 3px;
    }
  }
  .time {
    // padding: 10px;
    // background: #f8f8f8;
    border-radius: 5px;
    font-size: 12px;
    color: #6d7278;
    .begin_time {
      span {
        font-weight: 400;
      }
    }
    .begin_time + .begin_time {
      margin-top: 5px;
    }
  }
}

.question-detail-wrapper {
  margin: 10px auto 0 auto;
  // width: calc(100% - 50px);
  .gray {
    color: #666666;
  }
  .green {
    color: #6ed401;
  }
  .red {
    color: #ff0000;
  }
  .yellow {
    color: #f4d75e;
  }
  .line-20 {
    line-height: 20px;
  }

  pre {
    word-break: break-word;
    white-space: pre-wrap;
    img {
      max-width: 100%;
      object-fit: contain;
    }
  }

  /deep/ .van-tabs {
    .van-tabs__line {
      background: #1890ff;
    }
    .van-tab--active {
      font-weight: 550;
    }
    .van-tabs__content {
      padding-bottom: 40px;
    }
  }
  .comlun {
    flex-direction: column;
    justify-content: space-between;
  }
  
  /deep/.van-panel {
    .van-panel__header {
      padding-top: 14px;
      padding-bottom: 14px;
      .van-cell__title {
        font-size: 15px;
        color: #333333;
        font-weight: 550;
      }
    }
    .van-panel__content {
      padding: 0 20px;
    }
  }
  /deep/ .van-panel::after {
    content: none;
  }
  .question {
    margin-top: 10px;
    /deep/ img {
      max-width: 100%;
    }
    .video-wrapper {
      // width: 50%;
      video {
        width: 100%;
      }
    }

    .question-type {
      font-weight: bold;
      margin: 16px 0 10px;
      .isExpand{
        margin-left: 10px;
        color: #1890FF;
        font-weight: normal;
      }
      .top__title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #333333;
        text-align: left;
        font-style: normal;
        padding: 0 8px;
        position: relative;
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 8px;
          background: #1890FF;
          opacity: 0.15;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

    }
    .question-item{
      display: flex; 
      justify-content: space-between; 
      line-height: 14px;
      margin: 10px 0;
      span{
        color: #333;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

</style>