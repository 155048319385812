export default {
  text001: '上一位',
  text002: '下一位',
  text003: '已筛选',
  text004: '待筛选',
  text005: '已失效',
  text006: '返回',
  text007: '简历信息',
  text008: 'AI视频面试',
  text009: '在线笔试',
  text010: '认知能力',
  text011: '职业性格',
  text012: 'Meritas-心理健康',
  text013: '代码考试',
  text014: 'PDP测试报告',
  text015: '第三方测评',
  text016: '线下面试结果',
  text017: '面试信息',
  text018: '备注',
  text019: '附加信息',
  text020: '该候选人共面试了{count}次',
  text021: 'AI视频记录',
  text022: 'AI报告',
  text023: '笔试报告',
  text0231: '阅读题',
  text024: '论述题',
  text025: '客观题',
  text026: '选择语言',
  text027: '{0}岁',
  text028: 'L4-总分',
  text028_1: '总分',
  text029: '生成中',
  text030: '模型训练中',
  text031: 'L2-总分',
  text031_1: '总分',
  text032: '视频人工总分',
  text033: '考核得分',
  text034: '{0}分',
  text035: 'L2-AI表情',
  text035_1: 'AI表情',
  text036: 'L2-AI职业形象',
  text036_1: 'AI职业形象',
  text037: 'L2-AI声音',
  text037_1: 'AI声音',
  text038: 'L3-AI篇章级语义',
  text038_1: 'AI篇章级语义',
  text039: '候选人尚未被邀请参加AI面试',
  text040: '候选人尚未完成AI面试',
  text041: '简历解析中',
  text042: '您无权限查看',
  text043: '编程题',
  text044: '转发',
  text045: '评价记录',
  text046: '通过',
  text047: '已通过',
  text048: '待定',
  text049: '已待定',
  text050: '拒绝',
  text051: '已拒绝',
  text052: '拒绝面试',
  text053: '接受面试',
  text054: '请输入邮箱',
  text055: '邮箱',
  text056: '确认',
  text057: '您已通过简历',
  text058: '是否立即设置面试时间',
  text059: '不再提示',
  text060: '暂不设置',
  text061: '设置时间',
  text062: '简体中文',
  text063: '繁体中文',
  text064: '已经是第一位候选人了',
  text065: '已经是最后一位候选人了',
  text066: '原始简历',
  text067: '标准简历',
  text068: '应聘登记表',
  text069: '操作成功',
  text070: '操作失败',
  text071: '链接已失效',
  text072: '转发成功',
  text073: '分享成功',
  text074: '邮箱格式不正确',
  text075: '候选人暂无面试视频可查阅',
  text076: '候选人尚未完成AI测评',
  text077: '报告总结',
  text078: '视频面试',
  text079: '自定义试题',
  text080: '模拟编程',
  text081: '男',
  text082: '女',
  text083: '未知',
  text084: '防作弊记录',
  text085: '本科',
  english: 'L2-英文',
  应届生: '应届生',
  初中及以下: '初中及以下',
  highSchool: '⾼中/中专/中技',
  '⼤专': '⼤专',
  本科: '本科',
  硕士: '硕士',
  博士: '博士',
  不限: '不限',
  text086: 'L5-AI视频总分',
  text086_1: 'AI视频总分',
  text087: 'L2-AI总分',
  text087_1: 'AI总分',
  // english: 'L2-英文',
  text088: '防作弊记录',
  text090: '英文笔试总分',
  meritas: 'MeriTas测评',
  meritas_1: 'MeriTas测评得分',
  wqbsr:'完全不胜任',
  bushengren:'不胜任',
  shengren:'胜任',
  chaochuqiwang:'超出期望',
  youxiu:'优秀',
  text100: '综合总分',
  text100_1: '粤语能力',
  text100_2: '英语能力',

}
