import service from '@/config/http'
import qs from 'qs'

export const interviewerPlan = data => {
  const req = {
    url: '/candidate/interviewer/save/interviewer/time/plan',
    data,
    method: 'post',
  }
  return service(req)
}

export const searchRecommendResume = data => {
  const req = {
    url: '/candidate/interviewer/search/recommend/resume',
    data,
    method: 'post',
  }
  return service(req)
}

export const searchInterviewResume = data => {
  const req = {
    url: '/candidate/interviewer/search/interview/resume',
    data,
    method: 'post',
  }
  return service(req)
}

export const jobSeekerChannelOperate = data => {
  const req = {
    url: '/candidate/interviewer/operate/jobSeekerChannel',
    data,
    method: 'post',
  }
  return service(req)
}

// 评价简历或者报告
export const addComment = data => {
  const req = {
    url: '/candidate/EstimateResume/EstimateResume/addEstimateResume',
    data,
    method: 'post',
  }
  return service(req)
}

// 评价简历或者报告
export const getOperateStatus = data => {
  const req = {
    url: '/candidate/interviewer/operate/status',
    data,
    method: 'post',
  }
  return service(req)
}
export const jobSeekerChannelDetailStatus = data => {
  const req = {
    url: '/candidate/interviewer/operate/detailStatus',
    data,
    method: 'post',
  }
  return service(req)
}

//根据jobseekerChannelId查jobseekerId
export const getJobseekerId = data => {
  const req = {
    url: '/candidate/jobseekerChannel/jobseekerChannel/getJobseekerId',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}

//获取面试官的空余时间
export const getInterviewerTime = data => {
  const req = {
    url: '/candidate/jobseekerChannel/jobseekerChannel/getInterviewerTime',
    method: 'post',
    data,
  }
  return service(req)
}

//面试官接受或者拒绝hr安排的面试

export const interviewerReceiveStatus = data => {
  const req = {
    url: '/candidate/interviewer/receive/status',
    method: 'post',
    data,
  }
  return service(req)
}

//查询面试官接受或者拒绝了hr安排的面试
export const getInterviewerReceiveStatus = data => {
  const req = {
    url: '/candidate/interviewer/interview/receive/status',
    method: 'post',
    data,
  }
  return service(req)
}

//获取候选人是否收到ai面试邀请
export const getAiNoticeStatus = data => {
  const req = {
    url: '/candidate/jobseekerChannel/jobseekerChannel/getAiNoticeStatus',
    method: 'post',
    data,
  }
  return service(req)
}

//根据面试轮次id获取面试评价表
export const getAssessmentByRoundId = data => {
  const req = {
    url: '/candidate/assessment/getAssessmentByRoundId',
    data,
    method: 'post',
  }
  return service(req)
}

//新增反馈记录
export const saveOrUpdateAssessmentRecord = data => {
  const req = {
    url: '/candidate/assessment/saveOrUpdateAssessmentRecord',
    data,
    method: 'post',
  }
  return service(req)
}

//查看反馈记录
export const getAssessmentRecord = data => {
  const req = {
    url: '/candidate/assessment/getAssessmentRecord',
    data,
    method: 'post',
  }
  return service(req)
}

//获取获取系统模板
export const getTempleteList = data => {
  const req = {
    url: '/candidate/registerForm/info/getTempleteList',
    params: data,
  }
  return service(req)
}

//添加或保存应聘注册表项目
export const getEmployInfo = data => {
  const req = {
    url: '/candidate/registerForm/info/getEmployInfo',
    params: data,
  }
  return service(req)
}
/**
 * 查询考试/测评记录
 * @param {*} data
 */
export const getTestRecord = data => {
  const req = {
    url: '/candidate/jobseekerChannel/getTestRecord',
    method: 'post',
    data,
  }
  return service(req)
}
/**
 * 保存考试/测评记录
 * @param {*} data
 */
export const saveTestRecord = data => {
  const req = {
    url: '/candidate/jobseekerChannel/saveTestRecord',
    method: 'post',
    data,
  }
  return service(req)
}
/**
 * 删除考试/测评记录
 * @param {*} data
 */
export const deleteTestRecord = data => {
  const req = {
    url: '/candidate/jobseekerChannel/deleteTestRecord',
    method: 'post',
    data,
  }
  return service(req)
}
/**
 * 修改考试/测评记录
 * @param {*} data
 */
export const updateTestRecord = data => {
  const req = {
    url: '/candidate/jobseekerChannel/updateTestRecord    ',
    method: 'post',
    data,
  }
  return service(req)
}

//微信公众号推荐职位列表
export const recommendPositionList = data => {
  const req = {
    url: '/candidate/interviewer/wx/recommend/position/list    ',
    method: 'post',
    data,
  }
  return service(req)
}

//微信公众号面试职位列表
export const interviewPositionList = data => {
  const req = {
    url: '/candidate/interviewer/wx/interview/position/list    ',
    method: 'post',
    data,
  }
  return service(req)
}
//简历筛选，用人部门筛选、安排面试人员的接口都调这个
export const listUnfrozenUser = data => {
  return service({
    url: '/candidate/jobseekerChannel/jobseekerChannel/listUnfrozenUser',
    method: 'post',
    data: qs.stringify(data),
  })
}
// candidate/offlineInterviewResult/list  招商获取线下面试结果
export const offlineInterviewResultList = data => {
  const req = {
    url: '/candidate/offlineInterviewResult/list',
    data: qs.stringify(data),
    method: 'post',
  }
  return service(req)
}
// /candidate//jobseekerChannel/jobseekerChannel/getPreviewResumeUrl
export const getPreviewResumeUrl = data => {
  const req = {
    url: '/candidate//jobseekerChannel/jobseekerChannel/getPreviewResumeUrl',
    method: 'get',
    params: data,
  }
  return service(req)
}
/**
 * 查询候选人附件信息
 * @param data
 * @returns
 */
export const queryCandidateAttachInfo = data => {
  return service({
    url: '/candidate/attachInfo/queryCandidateAttachInfo',
    method: 'post',
    data,
  })
}

/**
 * 新增候选人附件信息
 * @param data
 * @returns
 */
export const insertCandidateAttachInfo = data => {
  return service({
    url: '/candidate/attachInfo/insertCandidateAttachInfo',
    method: 'post',
    data,
  })
}

/**
 * 删除候选人附加信息
 * @param data
 * @returns
 */
export const deleteCandidateAttachInfo = data => {
  return service({
    url: '/candidate/attachInfo/deleteCandidateAttachInfo',
    method: 'post',
    data,
  })
}
/**
 * 获取模板字段
 * @param data
 */
export const queryMessageTemplateField = data => {
  const req = {
    url: '/candidate/messageTemplate/queryMessageTemplateField',
    data,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return service(req)
}
//发送Offer记录
export const queryOfferSendRecord = data => {
  return service({
    url: '/candidate/offer/queryOfferSendRecord',
    data,
    method: 'post',
  })
}
// candidate/refuseReason/queryRefuseReason
// reasonType  0-AI面试 1-安排面试 2-录用  拒绝原因查询
export const queryRefuseReason = data => {
  return service({
    url: '/candidate/refuseReason/queryRefuseReason',
    data,
    method: 'post',
  })
}
// candidate/refuseReason/submitRefuseReason
// reasonType 0-AI面试 1-安排面试 2-录用
// jobSeekerChannelId 候选人id
// reasonDetails 为reasonId和reasonRemark的数组
export const submitRefuseReason = data => {
  return service({
    url: '/candidate/refuseReason/submitRefuseReason',
    data,
    method: 'post',
  })
}
export const getResumeUrlList = data => {
  return service({
    url: '/candidate/jobseekerResume/getList',
    method: 'get',
    params: data,
  })
}
/**
 * 3、pdp统计
 * url： /candidate/pdp/statistics
 * type： post
 * 参数
 * "jobseekerChannelId": 30, 用户 jobseekerChannelId
 * "companyId" : "1111111" 公司id
 */
export const pdpStatistics = data => {
  return service({
    url: '/candidate/pdp/statistics',
    method: 'post',
    data,
  })
}
/**
 * 查询背景调查资料
 * POST请求，JSON传参
 * jobseekerChannelId - 字符串类型 - 候选人id
 */
export const queryCheck = data => {
  return service({
    url: '/candidate/entry/zhongjun/check/query',
    method: 'post',
    data,
  })
}
/**
 * 查询背景调查资料
 * POST请求，JSON传参
 * jobseekerChannelId - 字符串类型 - 候选人id
 */
export const updatePlanInterviewStatus = data => {
  return service({
    url: '/candidate/refuseReason/updatePlanInterviewStatus',
    method: 'post',
    data,
  })
}
/**
 * 获取账号对填写反馈 面试未进行 取消面试的权限
 * @param data
 * @returns
 */
export const interviewerInfo = data => {
  return service({
    url: '/candidate/plan/planInterviewer/interviewerInfo',
    method: 'get',
    params: data,
  })
}
/**
 * 获取AI面试职位开始结束信息
 * @param data
 * @returns
 */
export const queryPositionInfo = data => {
  return service({
    url: '/candidate/chatboot/interview/positionInterviewinfo',
    method: 'get',
    params: data,
  })
}
/**
 * 获取腾讯验证url
 * @param data
 * @returns
 */
export const tencentFaceStartLogin = data => {
  return service({
    url: '/candidate/chatboot/interview/tencentFaceStartLogin',
    method: 'post',
    data,
  })
}
/**
 * 腾讯人脸识别结果保存
 * @param data
 * @returns
 */
export const syncCertificationResult = data => {
  return service({
    url: '/candidate/chatboot/interview/syncCertificationResult',
    method: 'post',
    data,
  })
}
/**
 * 腾讯人脸识别结果保存
 * @param data
 * @returns
 */
export const getOnlineUser = data => {
  return service({
    url: '/candidate/talentPool/talent/getOnlineUser',
    method: 'get',
    params: data,
  })
}
/**
 * 获取手机监控推流状态
 */
export const newBizidCheck = data => {
  return service({
    url: '/candidate/chatboot/interview/newBizidCheck',
    data: qs.stringify(data),
    method: 'post',
  })
}
/**
 * 查询是否开启手机监控
 */
export const mobileCheck = data => {
  return service({
    url: '/candidate/chatboot/interview/mobileCheck',
    data: qs.stringify(data),
    method: 'post',
  })
}
/**
 * 获取匿名评价配置
 * @returns
 */
export const getEvaluationConfig = () => {
  return service({
    url: '/candidate/evaluationConfig/getEvaluationConfig',
    method: 'get',
  })
}
/**
 * 提交 AI 面试意向
 * 入参：
 * String interviewId,   面试id
 * Integer aiIntention,1-接受，2-拒绝
 * @returns
 */
export const submitAiIntention = data => {
  return service({
    url: '/candidate/candidateStatus/submitAiIntention',
    method: 'get',
    params: data,
  })
}
/**
 * /candidate/interviewPlan/createOrUpdateInterviewAssessment
 * @param data "record":{}"feedback":{}
 * 其中record参数值为 saveOrUpdateAssessmentRecord 接口原有参数
 * feedback参数值为 createOrUpdateInterviewFeedback 接口原有参数
 * @returns
 */
export const createOrUpdateInterviewAssessment = data => {
  return service({
    url: '/candidate/interviewPlan/createOrUpdateInterviewAssessment',
    data,
    method: 'post',
  })
}
/**
 * 获取多次作答记录
 * @param data
 * @returns
 */
export const getInterviewInfos = data => {
  return service({
    url: '/candidate/interview/config/getInterviewInfos',
    method: 'post',
    data,
  })
}

/**
 * candidate/aiCheat/getCheatLogs
 * 获取切屏记录
 */
export const getCheatLogs = data => {
  return service({
    url: '/candidate/aiCheat/getCheatLogs',
    method: 'post',
    data,
  })
}
