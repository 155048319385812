export default {
  text001: '論述題',
  text002: '筆試報告',
  text003: 'TA的回答',
  text004: '正確答案',
  text005: '正確',
  text006: '錯誤',

  text007: '在線考試',
  text008: '該模塊涵蓋了候選人在以下所有題目類別上的答題情況，包括：單選題，多選題，判斷題，排序題，論述題。',
  text009: '{name} 回答及得分：{score}/{total}',
  text010: '備註：',
  text011: '選擇題，判斷題，排序題的分數只有在設定好題目分數以及標準答案後，才會涵蓋在圖表的總分當中。論述題的分數只有在設定好題目分數以及面試官評價過後，才會涵蓋在圖表的總分當中。',
  text012: '答題情況',
  text013: '正確題數：',
  text014: '錯誤題數：',
  text015: '( 本題不計分 )',
  text016: '沒有內容可以查看',
  text017: '回答不全',
  text018: '回答錯誤',
  text019: '回答正確',

  text030: '候選人未作答',
  text030_1: '未作答',
  text031: '{0}分',
  text032: '檢測結果為',
  text032_1: '回答正確數',
  text032_2: '回答錯誤數',
  text033: '正確答案',
  text034: '候選人答案',
  text035: '合格',
  text035_1: '有色盲色弱風險',
  text035_2: '色盲色弱',
  text036: '不合格',
  text037: '色盲檢測',
  text038: `紋身檢測`,
  text039: `手指/手臂完整性檢測`,
  text040: `手指靈活度檢測`,
  text041: `蹲起檢測`,
  text042: `本次不合格原因`,
  text043: '紋身和四肢檢測',

  spt: '視頻題',
  lst: '論述題',
  dxt: '單選題',
  ddxt: '多選題',
  tkt: '填空題',
  pdt: '判斷題',
  pxt: '排序題',
  bct: '編程題',
  kyt: '口音題',
}
