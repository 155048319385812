<template>
  <!-- <van-sticky @scroll="scrollTop"> -->
  <div class="menu_new">
    <div class="menu_menu">
      <img src="~@/assets/images/logo-1.png" alt="" />
      <div class="menu-icon el-icon-s-fold" @click="openMenu"></div>
    </div>
    <van-sticky>
      <div class="menu_banner">
        <img @click="regest" src="https://d-aimianshiguan.oss-cn-beijing.aliyuncs.com/resume/99805617469260554.png" width="100%" />
      </div>
    </van-sticky>
    <div class="menuIcon" @click="openMenu">
      <svg aria-label="AI得贤招聘官" class="sidebarMenuIcon_fgN0" width="24" height="24" viewBox="0 0 30 30" role="img" focusable="false">
        <title>AI得贤招聘官</title>
        <path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path>
      </svg>
    </div>
    <Tools ref="menuTools" :showTools="false"></Tools>
    <el-dialog :visible.sync="dialogVisible" fullscreen lock-scroll :show-close="false" :modal-append-to-body="false">
      <div class="menu-content">
        <div class="content-header">
          <img src="~@/assets/images/logo-1.png" alt="" />
          <div class="close-icon el-icon-close" @click="closeMenu"></div>
        </div>
        <div class="menu-list">
          <el-menu :default-active="activeIndex" @select="selectPage" class="menu-nav" unique-opened text-color="#333333" active-text-color="#333333">
            <el-menu-item index="/">
              <span slot="title">首页</span>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <span>产品特色</span>
              </template>
              <el-menu-item index="/product/aiinterview">L5级别AIGC面试</el-menu-item>
              <el-menu-item index="/product/coding">模拟编程</el-menu-item>
              <el-menu-item index="/product/recruitment">招聘管理系统</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <span>解决方案</span>
              </template>
              <el-menu-item index="/solution">解决方案</el-menu-item>
              <el-menu-item index="/case">客户案例</el-menu-item>
            </el-submenu>
            <el-menu-item index="/aigc">
              <span slot="title">AIGC大模型培训</span>
            </el-menu-item>
            <el-submenu index="5" v-if="false">
              <template slot="title">
                <span>价格</span>
              </template>
              <el-menu-item index="/price">价格</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <span>关于我们</span>
              </template>
              <el-menu-item index="/about">企业介绍</el-menu-item>
              <el-menu-item index="/team">团队介绍</el-menu-item>
              <el-menu-item index="/mandatePresentation">核心技术</el-menu-item>
            </el-submenu>
            <el-menu-item>
              <a slot="title" href="tel:400-096-3520">全国服务电话：400-096-3520</a>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </el-dialog>
    <div v-if="false" class="custom_dialog-overlay" @click="handleClick">
      <div class="custom_dialog">
        <img src="https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/m_dialog.png" width="351px" />
        <img src="https://aimianshiguan.oss-cn-beijing.aliyuncs.com/hc_pc/m_del.png" height="40px" class="del_btn" />
      </div>
    </div>
  </div>

  <!-- </van-sticky> -->
</template>
<script>
import vue from 'vue'
import { Dialog, Menu, MenuItem, Submenu } from 'element-ui'
vue.use(Dialog)
vue.use(Menu)
vue.use(MenuItem)
vue.use(Submenu)
import Tools from '@/components/tools'

export default {
  components: { Tools },
  data() {
    return {
      dialogVisible: false,
      activeIndex: '/',
      showDialog: false,
    }
  },
  mounted() {
    this.activeIndex = this.$route.path
    window.onscroll = function () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 27) {
        document.querySelector('.menuIcon').style.display = 'flex'
      } else {
        document.querySelector('.menuIcon').style.display = 'none'
      }
    }
    // if (this.$route.name === 'home') {

    // } else {
    //   document.querySelector('.menu').style.position = 'fixed'
    //   document.querySelector('.menu').style.background = '#ffffff'
    //   document.querySelector('.menu').style.padding = '0px 20px 0px 26px'
    //   window.onscroll = ''
    //   console.log(this.$route.name)
    // }
  },
  methods: {
    handleClick(e) {
      console.log(e)
      if (['custom_dialog-overlay', 'del_btn'].includes(e.target.className)) {
        this.showDialog = false
      } else {
        this.regest()
      }
    },
    regest() {
      this.$refs.menuTools && this.$refs.menuTools.toTryout()
    },
    scrollTop(e) {
      console.log('scrollTop==>>>>', e)
    },
    openMenu() {
      this.dialogVisible = true
      this.$emit('closeVideo')
    },
    closeMenu() {
      this.dialogVisible = false
      this.$emit('openVideo')
    },
    selectPage(index) {
      if (index) {
        if (index === this.$route.path) {
          location.reload()
        }
        this.$router.push(index)
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        const pathName = this.$route.path
        this.showDialog = pathName === '/'
        console.log('route', this.showDialog)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.menu_new {
  width: 100%;
  // position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  background: #ffffff;
  padding: 0px 20px 0px 26px;
  height: 180px;
  /deep/ .el-dialog__header,
  /deep/.el-dialog__body {
    padding: 0;
  }
  /deep/ .menu-list {
    padding: 10px 50px;
    .menu-nav {
      border: 0;
      > li {
        border-bottom: 1px solid #dddddd;
        color: #333333 !important;
        font-weight: 600;
        font-size: 14px;
        a {
          color: #333333 !important;
        }
      }
      li,
      .el-submenu__title {
        padding: 0 !important;
      }
    }
    .el-menu-item:focus,
    .el-menu-item:hover {
      background-color: #ffffff;
    }
    .el-submenu__title:focus,
    .el-submenu__title:hover {
      background-color: #ffffff;
    }
    .el-submenu {
      .el-menu-item {
        color: #979797 !important;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
        height: 30px;
      }
    }
    .el-submenu .el-menu {
      padding-bottom: 10px;
    }
  }

  > .menu-icon {
    float: right;
    color: #1990ff;
    font-size: 25px;
    line-height: 36px;
    display: block;
  }
  .menu-content {
    .content-header {
      background: #ffffff;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px 0px 26px;
      > img {
        height: 34px;
      }
      .close-icon {
        font-size: 35px;
        line-height: 50px;
        color: #1990ff;
      }
    }
  }
  .menu_menu {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > img {
      height: 36px;
      float: left;
    }
  }
  .menu_banner {
    position: absolute;
    // top: 60px;
    // height: 120px;
    width: 100%;
    left: 0;
  }
}
.menuIcon {
  position: fixed;
  bottom: 86px;
  right: 10px;
  font-size: 35px;
  width: 64px;
  height: 35px;
  line-height: px;
  background: #ebedf0;
  color: #1c1e21;
  border-radius: 5px;
  display: none;
  justify-content: center;
  align-items: center;
  .sidebarMenuIcon_fgN0 {
    display: block;
  }
}
.custom_dialog-overlay {
  z-index: 111;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  .custom_dialog {
    height: 416px;
    z-index: 10;
    top: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 351px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    .del_btn {
      // position: absolute;
      cursor: pointer;
      // right: -25px;
      // top: -25px;
    }
  }
}
</style>
